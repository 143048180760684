// CONSTANTS
const visibleClass = "ag-is-open";
const openClass = "ag-is-spread";
const currentClass = "ag-is-current";

/**
 * Tabs Class
 * element: Main javascript element (js-fabs)
 */
export class Fabs {
  /**
   * Main constructor
   * Set:
   *  - Tabs
   *  - Targets
   *  - Nav
   *  - Function binding
   */
  constructor({ element }) {
    this.element = element;
    this.fabs = this.element.querySelectorAll(".js-fab-action");
    this.fabsTarget = this.element.querySelectorAll(".js-menu-target");
    this.close = this.element.querySelectorAll(".js-fab-close");
    this.handleFabClick = this.handleFabClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.init();
  }

  /**
   * Init function
   * Adds tabs listeners
   * Show the first tab
   */
  init() {
    this.fabs.forEach(element => {
      element.addEventListener("click", e => this.handleFabClick(e, element));
    });
    this.close.forEach(element => {
      element.addEventListener("click", e => this.handleCloseClick(e, element));
    });
  }

  /**
   * Hide all tabs and show the specific target
   * @param {Object} e Event
   * @param {Object} element This element was clicked
   */
  handleFabClick(e, element) {
    e.preventDefault();
    this.element.classList.add(openClass);
    const targetID = element.getAttribute("href").replace("#", "");
    this.hideMenu();
    this.showSpecificFab(targetID);
    this.fabs.forEach(fab => {
      fab.classList.remove(currentClass);
    });
    element.classList.add(currentClass);
  }
  /**
   * Hide all targets on the instance
   */
  hideMenu() {
    this.fabsTarget.forEach((element, index) => {
      element.classList.remove(visibleClass);
    });
  }
  /**
   * Show an specific target
   * @param {String} fabId ID of the target to show
   */
  showSpecificFab(fabId) {
    const fab = this.element.querySelector("#" + fabId);
    if (fab && fabId) {
      fab.classList.add(visibleClass);
    }
  }
  /**
   * Handler head click
   */
  handleCloseClick() {
    this.element.classList.remove(openClass);
    this.fabsTarget.forEach((element, index) => {
      element.classList.remove(visibleClass);
    });
    this.fabs.forEach(fab => {
      fab.classList.remove(currentClass);
    });
  }
}
