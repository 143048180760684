export class ScrollMenu {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    [...this.element.children].forEach(li => {
      li.addEventListener("click", event => {
        event.preventDefault();
        let splitHref = li.children[0].href.split("/");
        let selector = splitHref[splitHref.length - 1];
        let scrollTo = document.querySelector(selector);
        scrollTo.scrollIntoView({ behavior: "smooth" });
      });
    });
  }
}
