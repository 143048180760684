import "./main.scss";
import * as UTILS from "./utils/Utils";

import { Header } from "./layout/Header";
import { CollapsibleMenu } from "./components/Collapsible-menu";
import { ContextualMenu } from "./components/Contextual-menu";
import { Navsticky } from "./components/NavSticky";
import { Navbar } from "./components/Navbar";
import { Sharebox } from "./components/Sharebox";
import { Collapsible } from "./components/Collapsible";
import { Tabs } from "./components/Tabs";
import { Fabs } from "./components/Fab-spread";
import { Malla } from "./components/Malla";
import { Slider } from "./components/Swiper-slider";
import { ScrollMenu } from "./components/Scroll-menu";
import { VideoGallery } from "./components/Video-gallery";
import { SearchForm } from "./forms/Search-form";
import { FormValidation } from "./forms/Form-validation";
import "./components/Dropdown";
import "./components/Modal-Gallery";
import "./components/Dialog-search";
import { AlertTransition } from "./components/Alert-transition";
import { PagePinned } from "./components/Pinned-page";
import { PanelScroll } from "./components/Panel-scroll";
import { dismissibleElementHandler } from "./components/Element-handler";
import { videoPlay } from "./components/Video-play";
import "./components/cookies";

class App {
  constructor() {
    this.init();
    this.handleResize = this.handleResize.bind(this);
    this.isMobile = UTILS.isMobile();
  }

  handleResize() {
    this.isMobile = UTILS.isMobile();
  }

  init() {
    new Header();
    window.addEventListener("scroll", this.handleResize);

    // Collapsible items
    if (this.isMobile) {
      const collapsibleItems = document.querySelectorAll(
        ".js-collapsible-menu"
      );
      collapsibleItems.forEach(item => {
        new CollapsibleMenu({
          element: item
        });
      });
    }
    // header sticky
    const navStickyElements = document.querySelectorAll(".js-navsticky");
    navStickyElements.forEach(item => {
      new Navsticky({
        element: item
      });
    });
    // Navbar plugin
    const navBarElements = document.querySelectorAll(".js-navbar");
    navBarElements.forEach(item => {
      new Navbar({
        element: item
      });
    });

    // Sharebox plugin
    const shareBoxElement = document.querySelectorAll(".js-sharebox");
    shareBoxElement.forEach(item => {
      new Sharebox({
        element: item
      });
    });

    // Contextual Menu plugin
    const contextualMenuItems = document.querySelectorAll(".js-contextual");
    contextualMenuItems.forEach(item => {
      new ContextualMenu({
        element: item
      });
    });

    // Collapsible
    const collapsible = document.querySelectorAll(".js-collapsible");
    collapsible.forEach(item => {
      new Collapsible({
        element: item
      });
    });

    // Tabs
    const tabs = document.querySelectorAll(".js-tabs");
    tabs.forEach(item => {
      new Tabs({
        element: item
      });
    });

    // Fabs
    const fabs = document.querySelectorAll(".js-fab-spread");
    fabs.forEach(item => {
      new Fabs({
        element: item
      });
    });

    // Swiper Slider
    const sliders = document.querySelectorAll(".swiper-container");
    sliders.forEach(slider => {
      new Slider(slider);
    });

    // Scroll Menu
    const scrollMenu = document.querySelectorAll(".ag-js-ScrollMenu");
    scrollMenu.forEach(menu => {
      new ScrollMenu(menu);
    });

    // Video Gallery
    const videoGallery = document.querySelectorAll(".js-video");
    videoGallery.forEach(gallery => {
      new VideoGallery(gallery);
    });

    const forms = Array.from(document.forms);
    forms.forEach(form => {
      // Form validation
      if (form.classList.contains("ag-js-form-validate")) {
        new FormValidation(form);
      }
      // Search Form
      if (form.name === "searchForm") {
        new SearchForm(form);
      }
    });

    // Malla
    new Malla();

    // Alerts
    const alertsBtnClose = Array.from(
      document.getElementsByClassName("js-messageClose")
    );
    alertsBtnClose.forEach(x => {
      new AlertTransition(x);
    });

    // Page pinned
    const pinnedPages = Array.from(
      document.getElementsByClassName("js-pinnedPage")
    );
    pinnedPages.forEach(page => {
      new PagePinned(page);
    });

    // Panel scroll
    const panelScroll = Array.from(
      document.querySelectorAll(".js-panelAction")
    );
    panelScroll.forEach(panel => {
      new PanelScroll(panel);
    });

    // remove element
    const disableClassElements = document.querySelectorAll(
      ".js-btn-disable-element"
    );
    disableClassElements.forEach(item => {
      new dismissibleElementHandler({
        element: item
      });
    });

    const elementPlay = document.querySelectorAll(".js-video-cover");
    elementPlay.forEach(item => {
      new videoPlay({
        element: item
      });
    });
  }
}

const APP = new App();
