export class PagePinned {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    if (this.element.getAttribute("id") == window.location.hash.substr(1)) {
      setTimeout(() => {
        this.element.removeAttribute("style");
        this.element
          .closest(".ag-c-collapsible__item")
          .classList.add("is-collapsible-opened");
      }, 50);
    }
  }
}
