/**
 * Search Form Class
 * element: Form with class (ag-js-searchForm)
 */
export class SearchForm {
  /**
   * Main constructor
   * Set:
   *  - Form fields
   */
  constructor(form) {
    // Form elements
    this.form = form;
    this.searchText = this.form["search_text"];
    this.searchMeta = this.form["search_meta"];
    this.orderBy = this.form["order_by"];
    this.listsWrapper = this.form.getElementsByClassName("ag-js-filters");
    this.admisionTipoDeProgramaList = {
      ul: this.form.querySelector("#id_admision_tipos_de_programa"),
      wrapper: this.form.querySelector(
        "[data-field-id='id_admision_tipos_de_programa']"
      ),
      checkboxes: this.form.querySelectorAll(
        "[name='admision_tipos_de_programa']"
      )
    };
    this.programaTipoDeProgramaList = {
      ul: this.form.querySelector("#id_programa_tipos_de_programa"),
      wrapper: this.form.querySelector(
        "[data-field-id='id_programa_tipos_de_programa']"
      ),
      checkboxes: this.form.querySelectorAll(
        "[name='programa_tipos_de_programa']"
      )
    };
    this.sectionCategoriesList = {
      ul: this.form.querySelector("#id_section_categories"),
      wrapper: this.form.querySelector("[data-field-id='id_section_categories"),
      checkboxes: this.form.querySelectorAll("[name='section_categories")
    };
    this.noticiaTopicCategoriesList = {
      ul: this.form.querySelector("#id_noticia_topic_categories"),
      wrapper: this.form.querySelector(
        "[data-field-id='id_noticia_topic_categories']"
      ),
      checkboxes: this.form.querySelectorAll(
        "[name='noticia_topic_categories']"
      )
    };
    this.noticiaAudienciasList = {
      ul: this.form.querySelector("#id_noticia_audiencias"),
      wrapper: this.form.querySelector(
        "[data-field-id='id_noticia_audiencias']"
      ),
      checkboxes: this.form.querySelectorAll("[name='noticia_audiencias']")
    };
    this.eventoTopicCategoriesList = {
      ul: this.form.querySelector("#id_evento_topic_categories"),
      wrapper: this.form.querySelector(
        "[data-field-id='id_evento_topic_categories']"
      ),
      checkboxes: this.form.querySelectorAll("[name='evento_topic_categories']")
    };
    this.eventoAudienciasList = {
      ul: this.form.querySelector("#id_evento_audiencias"),
      wrapper: this.form.querySelector(
        "[data-field-id='id_evento_audiencias']"
      ),
      checkboxes: this.form.querySelectorAll("[name='evento_audiencias']")
    };

    // Filter tags elements
    this.filterTagWrappers = document.querySelectorAll(
      ".ag-js-filterTagWrapper"
    );
    this.filterTagSet = document.querySelectorAll(".ag-js-filterTagSet");
    this.filterTagDeleteBtn = document.querySelectorAll(".ag-js-filterDelete");
    this.filterTagTemplate = document.querySelector("#ag-js-filterTagTemplate");
    this.filterDropdownBtn = document.querySelectorAll(".ag-js-filtersBtn");

    // Css classes
    this.fieldHiddenClass = "ag-is-filterHidden";
    this.filterTagClass = ".ag-js-filterTag";
    this.filterTagTextClass = ".ag-js-filter-tag__text";
    this.deleteTagIconClass = ".i-close";
    this.hideDeleteTagsButtonsClass = "ag-is-remove";

    this.init();
  }

  /**
   * Init function
   * Handler clicks
   */
  init() {
    this.handleSearchMetaField();
    this.handleSearchFiltersChange();
    if (this.hasFilterTags()) {
      this.handleFormFiltersTags();
    }
  }

  // FILTER FIELDS

  /**
   * Handle search_meta field events
   */
  handleSearchMetaField() {
    this.hideFiltersField(this.getFiltersBySearchMeta());
    this.searchMeta.addEventListener("change", () => {
      this.hideFiltersField(this.getFiltersBySearchMeta());
      this.updateFiltersBtn(this.getFiltersBySearchMeta());
    });
  }

  /**
   * Handle filters change
   */
  handleSearchFiltersChange() {
    this.updateFiltersBtn(this.getFiltersBySearchMeta());
    let filters = this.getFiltersOptions();
    filters.forEach(filter => {
      filter.checkboxes.forEach(checkbox => {
        checkbox.addEventListener("change", () => {
          this.updateFiltersBtn(this.getFiltersBySearchMeta());
        });
      });
    });
  }

  /**
   * Handle change on filters checkboxes
   * @param {HTMLElement[]} wrappers filters wrappers
   * @param {Object[]} filters objects
   */
  filterCheckboxesBtnUpdate(wrappers, filters) {
    const filterDropdownBtn = this.filterDropdownBtn;
    Array.from(wrappers).forEach(wrapper => {
      let checkboxes = filters.reduce((accumulator, element) => {
        return accumulator.concat(Array.from(element.checkboxes));
      }, []);
      let checkboxesChecked = checkboxes.filter(checkbox => checkbox.checked);
      if (checkboxesChecked.length) {
        filterDropdownBtn.forEach(btn => {
          btn.innerHTML =
            checkboxesChecked.length > 1
              ? "Varios"
              : wrapper.querySelector(`[for='${checkboxesChecked[0].id}']`)
                  .innerHTML;
        });
      } else {
        filterDropdownBtn.forEach(btn => {
          btn.innerHTML = "Selecciona uno o varios filtros";
        });
      }
    });
  }

  /**
   * Get filters valid by search meta selected
   * @returns {Object[]} Array of objects.
   */
  getFiltersBySearchMeta() {
    const searchMeta = JSON.parse(
      this.searchMeta.options[this.searchMeta.selectedIndex].value
    );
    if (
      searchMeta.type === "model" &&
      searchMeta.app_label === "esc_web_noticias" &&
      searchMeta.model === "noticia"
    ) {
      return [this.noticiaTopicCategoriesList, this.noticiaAudienciasList];
    } else if (
      searchMeta.type === "model" &&
      searchMeta.app_label === "esc_web_eventos" &&
      searchMeta.model === "evento"
    ) {
      return [this.eventoTopicCategoriesList, this.eventoAudienciasList];
    } else if (
      searchMeta.type === "model" &&
      searchMeta.app_label === "esc_web_admisiones" &&
      searchMeta.model === "admision"
    ) {
      return [this.admisionTipoDeProgramaList];
    } else if (
      searchMeta.type === "model" &&
      searchMeta.app_label === "esc_web_programas" &&
      searchMeta.model === "programa"
    ) {
      return [this.programaTipoDeProgramaList];
    } else if (searchMeta.type === "section") {
      return [this.sectionCategoriesList];
    } else {
      return [];
    }
  }

  /**
   * Hide filters fields
   * @param {Object[]} except form filters objects
   */
  hideFiltersField(except = []) {
    except = except.filter(item => item.ul);
    this.getFiltersOptions().forEach(filter => {
      if (filter && except.length && except.includes(filter)) {
        filter.wrapper.classList.remove(this.fieldHiddenClass);
      } else {
        filter.wrapper.classList.add(this.fieldHiddenClass);
        filter.checkboxes.forEach(input => this.clearFilter(input, false));
      }
    });

    Array.from(this.listsWrapper).forEach(wrapper => {
      wrapper.classList.toggle(this.fieldHiddenClass, !except.length);
    });
  }

  /**
   * Update filters btn
   * @param {Object[]} filters form filters objects
   */
  updateFiltersBtn(filters = []) {
    filters = filters.filter(item => item.ul);
    this.getFiltersOptions().forEach(filter => {
      if (filter && filters.length && filters.includes(filter)) {
        this.filterCheckboxesBtnUpdate(this.listsWrapper, filters);
      }
    });
  }

  /**
   * Get filters options
   * @return {Object[]} array con los filtros aplicables
   */
  getFiltersOptions() {
    return [
      this.admisionTipoDeProgramaList,
      this.programaTipoDeProgramaList,
      this.sectionCategoriesList,
      this.noticiaTopicCategoriesList,
      this.noticiaAudienciasList,
      this.eventoTopicCategoriesList,
      this.eventoAudienciasList
    ].filter(filter => filter.ul);
  }

  // FILTER TAGS

  hasFilterTags() {
    return (
      this.filterTagWrappers && this.filterTagSet && this.filterTagTemplate
    );
  }

  /**
   * Create and return all the filters of the search form.
   * @return {HTMLElement[]} Array of filters
   */
  getFilters() {
    const filterTypes = ["checkbox", "select-one"];
    return Array.from(this.form.elements).filter(element => {
      return filterTypes.includes(element.type);
    });
  }

  /**
   * Find the first radio button that has no value from a group of radio buttons.
   *
   * @returns {Boolean} The search meta element has value.
   */
  searchMetaIsEmpty() {
    return !this.searchMeta.options[this.searchMeta.selectedIndex].value;
  }

  /**
   * Clear the value of a filter.
   *
   * @param {HTMLElement} input The checkbox to un-check.
   * @param {Boolean} submit boolean for submit the form.
   */
  clearFilter(input, submit = true) {
    // Clear checkboxes
    input.checked = false;
    input.dispatchEvent(new Event("change"));
    if (submit) {
      this.form.submit();
    }
  }

  /**
   * Create an HTML tag element with the given text.
   *
   * @param {String} text String that the tag should contain.
   * @param {HTMLElement} input element The form element from which to create tags.
   *
   * @returns {HTMLElement}
   */
  getTag(text, input) {
    const tag = this.filterTagTemplate.content.cloneNode(true);
    tag.querySelector(this.filterTagTextClass).innerHTML = text;

    // Search meta can not be removed.
    if (
      input.type === "select-one" &&
      input.name === this.searchMeta.name &&
      !this.searchMetaIsEmpty()
    ) {
      tag
        .querySelector(`${this.filterTagClass} ${this.deleteTagIconClass}`)
        .remove();
      return tag;
    }

    tag.querySelector(this.filterTagClass).addEventListener("click", () => {
      this.clearFilter(input);
    });
    return tag;
  }

  /**
   * Create and return filter tags for the given form element.
   *
   * @param {HTMLElement} element The form element from which to create tags.
   *
   * @returns {HTMLElement[]} Array of tags.
   */
  getFilterTags(element) {
    if (element.disabled) {
      return [];
    }
    if (element.type === "select-one" && element.name !== "order_by") {
      return Array.from(element.selectedOptions).map(option => {
        return this.getTag(option.label, element);
      });
    }
    if (element.checked && element.value.length && element.labels.length) {
      return [this.getTag(element.labels[0].innerHTML, element)];
    }
    return [];
  }

  /**
   * Reset all the filter tags for the search form.
   */
  resetFilterTags() {
    const tags = this.getFilters().reduce((accumulator, element) => {
      return accumulator.concat(this.getFilterTags(element));
    }, []);

    const hasFilters = tags.some(tag => {
      return tag.querySelector(this.deleteTagIconClass);
    });

    this.filterTagDeleteBtn.forEach(button => {
      button.classList.toggle(this.hideDeleteTagsButtonsClass, !hasFilters);
    });

    this.filterTagSet.forEach(container => {
      container.innerHTML = "";
      container.append(...tags);
    });
  }

  clearFormFilters() {
    this.filterTagWrappers.forEach(wrapper => {
      wrapper.querySelectorAll(this.filterTagClass).forEach(tag => {
        tag.dispatchEvent(new Event("click"));
      });
    });
    this.form.submit();
  }

  /**
   * Handle form filters tags
   */
  handleFormFiltersTags() {
    // Clear all filters action.
    this.filterTagDeleteBtn.forEach(element => {
      element.addEventListener("click", () => this.clearFormFilters());
    });

    // Reset tags filter on "reset" form event
    this.form.addEventListener("reset", () => this.clearFormFilters());

    this.resetFilterTags();
  }
}
