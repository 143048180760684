// CONSTANTS
const visibleClass = "ag-is-tab-visible";
const currentClass = "ag-is-tab-current";

/**
 * Tabs Class
 * element: Main javascript element (js-tabs)
 */
export class Tabs {
  /**
   * Main constructor
   * Set:
   *  - Tabs
   *  - Targets
   *  - Nav
   *  - Function binding
   */
  constructor({ element }) {
    this.element = element;
    this.tabs = this.element.querySelectorAll(".js-tab-action");
    this.tabsTarget = this.element.querySelectorAll(".js-tab-target");
    this.nav = this.element.querySelectorAll(".js-tabs-nav");
    this.handleTabClick = this.handleTabClick.bind(this);
    this.init();
  }

  /**
   * Init function
   * Adds tabs listeners
   * Show the first tab
   */
  init() {
    this.tabs.forEach(element => {
      element.addEventListener("click", e => this.handleTabClick(e, element));
    });
    this.showFirstTab();
  }

  /**
   * Hide all tabs and show the specific target
   * @param {Object} e Event
   * @param {Object} element This element was clicked
   */
  handleTabClick(e, element) {
    e.preventDefault();
    const targetID = element.getAttribute("href").replace("#", "");
    this.hideTabs();
    this.showSpecificTab(targetID);
    this.tabs.forEach(tab => {
      tab.classList.remove(currentClass);
    });
    element.classList.add(currentClass);
  }

  /**
   * Show the fist target on the instance
   * Add current class to first tab
   */
  showFirstTab() {
    this.tabsTarget.forEach((element, index) => {
      if (index === 0) element.classList.add(visibleClass);
    });
    this.tabs.forEach((tab, index) => {
      if (index === 0) tab.classList.add(currentClass);
    });
  }

  /**
   * Hide all targets on the instance
   */
  hideTabs() {
    this.tabsTarget.forEach((element, index) => {
      element.classList.remove(visibleClass);
    });
  }

  /**
   * Show an specific target
   * @param {String} tabId ID of the target to show
   */
  showSpecificTab(tabId) {
    const tab = this.element.querySelector("#" + tabId);
    if (tab && tabId) {
      tab.classList.add(visibleClass);
      this.scrollToNav(tab);
    }
  }

  /**
   * Scroll to specific tab
   */
  scrollToNav() {
    if (this.nav) {
      const offset = this.nav[0].offsetTop - 120;
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: "smooth"
      });
    }
  }
}
