export class CollapsibleMenu {
  constructor({ element }) {
    this.element = element;
    this.title = this.element.querySelectorAll(".js-collapsible-menu_title");
    this.isOpened = false;
    this.element.classList.add(this.isOpened ? "is-opened" : null);
    this.hanldeTitleClick = this.hanldeTitleClick.bind(this);
    this.init();
  }

  init() {
    this.title[0].addEventListener("click", this.hanldeTitleClick);
  }

  hanldeTitleClick() {
    this.element.classList.toggle("is-opened");
  }
}
