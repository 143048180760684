/**
 * Navbar Class
 * element: Main javascript element (js-navsticky)
 */
export class Navsticky {
  /**
   * Main constructor
   * Set:
   *  - Items
   *  - isSticky, currentScroll, hideMenu
   *  - Function binds
   */
  constructor({ element }) {
    this.element = element;
    this.handleScroll = this.handleScroll.bind(this);
    this.isSticky = false;
    this.init();
  }

  /**
   * Init function
   * Handler clicks
   * Scroll listener
   */
  init() {
    window.addEventListener("scroll", this.handleScroll);
  }
  /**
   * Handler scroll event
   */
  handleScroll() {
    const scrollPos = window.scrollY;
    const elementPos = 30;
    if (scrollPos > elementPos && !this.isSticky) {
      this.isSticky = true;
      this.element.classList.add("ag-is-sticky");
    }
    if (scrollPos < elementPos && this.isSticky) {
      this.isSticky = false;
      this.element.classList.remove("ag-is-sticky");
    }
  }
}
