import * as UTILS from "../utils/Utils";
/**
 * Contextual Menu Class
 * element: Main javascript element (js-contextual-menu)
 */
export class ContextualMenu {
  /**
   * Main constructor
   * Set:
   *  - Head element
   *  - Panel element
   *  - Sub items
   *  - Function binds
   */
  constructor({ element }) {
    this.element = element;
    this.links = this.element.querySelectorAll(".js-submenu");
    this.hanldeSubmenuClick = this.hanldeSubmenuClick.bind(this);
    this.hideMenu = false;
    this.init();
  }

  /**
   * Init function
   * Scroll handler to add stickey mode
   * Handler clicks
   */
  init() {
    this.links.forEach(element => {
      element.addEventListener("click", () => {
        this.hanldeSubmenuClick(element);
      });
    });
  }

  /**
   * Handler submenu click
   */
  hanldeSubmenuClick(item) {
    item.classList.toggle("is-submenu-opened");
  }
}
