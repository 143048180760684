import Swiper from "swiper";
export class Slider {
  constructor(HTMLElement) {
    this.element = HTMLElement;
    this.sliderType = this.element.dataset.sliderType;
    // this.handleResize = this.handleResize.bind(this);
    this.init();
  }

  init() {
    // window.addEventListener("resize", this.handleResize);
    this.render();
  }

  render() {
    switch (this.sliderType) {
      case "slideList":
        this.renderSlideListSlider();
        break;
      case "itemsList":
        this.renderItemsListSlider();
        break;
      case "imageList":
        this.renderImageListSlider();
        break;
      case "timeLine":
        this.renderTimeLineSlider();
        break;
      case "personList":
        this.renderPersonListSlider();
        break;
      case "curriculum":
        this.renderCurriculumSlider();
        break;
      case "scrollBar":
        this.renderscrollBar();
        break;
      case "galleryThumbs":
        this.renderGalleryThumbsSlider();
        break;
      case "slidelistpage":
        this.renderSlideListPage();
        break;
      case "slidegallery":
        this.renderSlideGallery();
        break;
      default:
        break;
    }
  }
  // handleResize() {
  //   this.render();
  // }

  renderSlideListSlider() {
    new Swiper(this.element, {
      watchOverflow: true,
      resizeReInit: true,
      autoHeight: true,
      pagination: {
        el: this.element.querySelector(".ag-c-swiper__pagination"),
        clickable: true
      },
      navigation: {
        nextEl: this.element.querySelector(".ag-c-swiper__next"),
        prevEl: this.element.querySelector(".ag-c-swiper__prev")
      }
    });
  }
  renderItemsListSlider() {
    var nextEl;
    var prevEl;
    var wrapper = this.element.closest(".ag-js-itemsListSwiperWrapper");
    if (wrapper) {
      nextEl = wrapper.querySelector(".ag-c-swiper__next");
      prevEl = wrapper.querySelector(".ag-c-swiper__prev");
    } else {
      nextEl = this.element.querySelector(".ag-c-swiper__next");
      prevEl = this.element.querySelector(".ag-c-swiper__prev");
    }
    new Swiper(this.element, {
      spaceBetween: 0,
      slidesPerView: "auto",
      watchOverflow: true,
      resizeReInit: true,
      centeredSlidesBounds: true,
      pagination: {
        el: this.element.querySelector(".ag-c-swiper__pagination"),
        clickable: true
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl
      }
    });
  }
  renderImageListSlider() {
    var nextEl;
    var prevEl;
    var wrapper = this.element.closest(".ag-js-imageListSwiperWrapper");
    if (wrapper) {
      nextEl = wrapper.querySelector(".ag-c-swiper__next");
      prevEl = wrapper.querySelector(".ag-c-swiper__prev");
    } else {
      nextEl = this.element.querySelector(".ag-c-swiper__next");
      prevEl = this.element.querySelector(".ag-c-swiper__prev");
    }
    new Swiper(this.element, {
      spaceBetween: 0,
      watchOverflow: true,
      resizeReInit: true,
      centeredSlidesBounds: true,
      slidesPerView: 1,
      breakpoints: {
        680: {
          slidesPerView: 2
        },
        767: {
          slidesPerView: 3
        },
        980: {
          slidesPerView: 4
        }
      },
      pagination: {
        el: this.element.querySelector(".ag-c-swiper__pagination"),
        clickable: true
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl
      }
    });
  }
  renderTimeLineSlider() {
    new Swiper(this.element, {
      noSwiping: true,
      noSwipingClass: "ag-time-line_swiper",
      breakpoints: {
        720: {
          slidesPerView: 4,
          noSwiping: false
        }
      },
      navigation: {
        nextEl: this.element.querySelector(".ag-c-swiper__next"),
        prevEl: this.element.querySelector(".ag-c-swiper__prev")
      }
    });
  }
  renderCurriculumSlider() {
    var nextEl = this.element
      .closest(".js-modal-in-swiper")
      .querySelector(".ag-c-swiper__next");
    var prevEl = this.element
      .closest(".js-modal-in-swiper")
      .querySelector(".ag-c-swiper__prev");
    new Swiper(this.element, {
      loop: false,
      spaceBetween: 0,
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: "auto"
        },
        1244: {
          slidesPerView: 10
        }
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl
      }
    });
  }
  renderPersonListSlider() {
    new Swiper(this.element, {
      spaceBetween: 0,
      slidesPerView: "auto",
      navigation: {
        nextEl: this.element.querySelector(".ag-c-swiper__next"),
        prevEl: this.element.querySelector(".ag-c-swiper__prev"),
        disabledClass: "swiper-button-disabled"
      },
      pagination: {
        el: this.element.querySelector(".ag-c-swiper__pagination"),
        clickable: true
      }
    });
  }

  renderscrollBar() {
    new Swiper(this.element, {
      slidesPerView: "auto"
    });
  }
  renderGalleryThumbsSlider() {
    new Swiper(this.element, {
      spaceBetween: 16,
      slidesPerView: 2,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        768: {
          spaceBetween: 32,
          slidesPerView: 3
        }
      },
      pagination: {
        el: this.element.querySelector(".swiper-pagination"),
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 1
      }
    });
  }
  renderSlideGallery() {
    var sliderGallery = new Swiper(this.element, {
      initialSlide: 1,
      effect: "coverflow",
      centeredSlides: true,
      watchOverflow: true,
      autoHeight: true,
      slidesPerView: "auto",
      slideActiveClass: "slide-galery-active",
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      coverflowEffect: {
        rotate: 0,
        stretch: -20,
        depth: 0,
        modifier: 1,
        slideShadows: false
      },
      breakpoints: {
        768: {
          coverflowEffect: {
            stretch: 0,
            depth: 400
          }
        }
      },
      navigation: {
        nextEl: this.element.querySelector(".ag-c-swiper__next"),
        prevEl: this.element.querySelector(".ag-c-swiper__prev")
      },
      pagination: {
        el: this.element.querySelector(".swiper-pagination"),
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 1
      }
    });
    //Verifica cualquier cambio que haya en el slider
    sliderGallery.on("observerUpdate", () => {
      sliderGallery.updateSlides();
    });
  }
  renderSlideListPage() {
    new Swiper(this.element, {
      spaceBetween: 0,
      slidesPerView: "auto",
      slideActiveClass: "slide-pagelist-active",
      navigation: {
        nextEl: this.element.querySelector(".ag-c-swiper__next"),
        prevEl: this.element.querySelector(".ag-c-swiper__prev")
      },
      pagination: {
        el: this.element.querySelector(".ag-c-swiper__pagination"),
        clickable: true
      }
    });
  }
}
