export class AlertTransition {
  /**
   * Main constructor
   * Parameter: html element
   */
  constructor(element) {
    this.element = element;
    this.waitingTimeToDisplay = 1000;
    this.init();
  }

  /**
   * Handles display and close-on-click event.
   */
  init() {
    const alertElement = this.element.closest(".js-messagesItem");
    setTimeout(() => {
      alertElement.classList.remove("ag-is-messageClose");
      alertElement.classList.add("ag-has-messageAnim");
    }, this.waitingTimeToDisplay);

    // Al hacer click en el botón cerrar agrega la clase ag-is-messageClose la cual contiene las animaciones y los estilos para cerrar la alerta
    this.element.addEventListener("click", () => {
      alertElement.classList.add("ag-has-messageClose");
    });
  }
}
