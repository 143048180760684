//Representa cada item del slider
const btnSlideGallery = Array.from(
  document.querySelectorAll(".ag-js-gallerySlide")
);

//Copia del slider-container para la modal
let sliderGallery = "";

//Id con la unica modal para la galeria
const modalGallery = document.getElementById("modalGallery");
//Body
const bodyGallery = document.getElementsByClassName("js-body");
//Botón cerrar de la modal
const btnClose = document.getElementsByClassName("js-gallery-modalClose");

btnSlideGallery.forEach(btnGallery => {
  btnGallery.addEventListener("click", function(event) {
    //Deshabilita los eventos clic para el contenido del body
    bodyGallery[0].classList.add("is-gallery-active");
    //Guarda el contenido del swiper-container
    sliderGallery = this.parentNode.parentNode;
    const copySlider = sliderGallery;

    //Agrega el slider al contenido de la modal
    modalGallery.lastElementChild.append(copySlider);

    //Agrega la clase que permite visualizar la modal
    modalGallery.classList.add("ag-is-gallery-overlayActive");

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  });
});

if (btnClose[0]) {
  btnClose[0].addEventListener("click", function(event) {
    //Verifica si la modal tiene o no la clase active para removerla
    if (modalGallery.classList.contains("ag-is-gallery-overlayActive")) {
      modalGallery.classList.remove("ag-is-gallery-overlayActive");
      //Habilita los eventos clic para el contenido del body
      bodyGallery[0].classList.remove("is-gallery-active");
      //Devuelve el contenido del swiper-container
      const copySliderModal = sliderGallery;
      //Agrega el slider al contenido principal
      modalGallery.parentNode.append(copySliderModal);
      //Limpia el contenido de la modal al cerrarla
      modalGallery.lastElementChild.innerHTML = "";
    }
  });
}
