/**
 * Video gallery Class
 * element: Main javascript element (js-video)
 */
export class VideoGallery {
  /**
   * Main constructor
   * Set:
   *  - Thumbnails
   *  - Cover
   *  - Video target
   *  - Function binds
   */
  constructor(element) {
    this.element = element;
    this.thumbnails = this.element.querySelectorAll(".js-video-play");
    this.cover = this.element.querySelector(".js-video-cover");
    this.videoTarget = this.element.querySelector(".js-video-target");
    this.videoAction = this.element.querySelector(".js-video-action");
    this.iframe = this.element.querySelector(
      ".js-video-target .ag-o-video iframe"
    );
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
    this.handleVideotargetClick = this.handleVideotargetClick.bind(this);
    this.activeThumbClass = "ag-is-video-active";
    this.init();
  }

  /**
   * Init function
   * Handler clicks
   */
  init() {
    this.addListenerToItems();
  }

  /**
   * Add proper listener to each item
   */
  addListenerToItems() {
    this.thumbnails.forEach(element => {
      element.addEventListener("click", () => {
        this.handleThumbnailClick(element);
      });
    });
    this.videoTarget.addEventListener("click", () => {
      this.handleVideotargetClick();
    });
  }

  /**
   * Handle the thumbnail click
   * It removes the cover and set the new SRC to the iframe
   * @param {Node} element Item click handler
   */
  handleThumbnailClick(element) {
    this.thumbnails.forEach(element => {
      element.classList.remove(this.activeThumbClass);
    });
    element.classList.add(this.activeThumbClass);
    const { video: videoUrl } = element.dataset;
    const provider = this.checkUrlProvider(videoUrl);
    const videoID = this.getVideoIDFromUrl(videoUrl);
    const videoURL =
      provider === "youtube"
        ? "https://www.youtube.com/embed/" +
          videoID +
          "?feature=oembed&autoplay=1"
        : "https://player.vimeo.com/video/" +
          videoID +
          "?autoplay=1&color=ffffff&title=0&byline=0&portrait=0";
    this.hideCover();
    this.iframe.setAttribute("src", videoURL);
  }

  /**
   * Handle the video target click
   */
  handleVideotargetClick() {
    this.hideCover();
    this.addAutoplayToSrc();
  }

  /**
   * Removes the cover on thumbnail click or cover click
   */
  hideCover() {
    this.cover.classList.remove("ag-is-cover");
    if (this.videoAction) {
      this.videoAction.classList.remove("ag-is-visible");
    }
    this.videoTarget.classList.remove("ag-is-covered");
  }

  /**
   * Add the autoplay param to the iframe
   */
  addAutoplayToSrc() {
    const symbol = this.iframe.src.indexOf("?") > -1 ? "&" : "?";
    this.iframe.setAttribute("src", this.iframe.src + symbol + "autoplay=1");
  }

  /**
   * It returns the proper video id from URL
   * @param {String} url video url
   * @returns {String} video ID
   */
  getVideoIDFromUrl(url) {
    const provider = this.checkUrlProvider(url);
    const ID =
      provider === "youtube" ? this.getYoutubeID(url) : this.getVimeoID(url);
    return ID;
  }

  /**
   * Get the Youtube ID from url
   * Supported URLS:
   * 'http://youtube.googleapis.com/v/4e_kz79tjb8?version=3';
   * 'https://www.youtube.com/watch?feature=g-vrec&v=Y1xs_xPb46M';
   * 'http://www.youtube.com/watch?feature=player_embedded&v=Ab25nviakcw#';
   * 'http://youtu.be/Ab25nviakcw';
   * 'http://www.youtube.com/watch?v=Ab25nviakcw';
   * '<iframe width="420" height="315" src="http://www.youtube.com/embed/Ab25nviakcw" frameborder="0" allowfullscreen></iframe>';
   * '<object width="420" height="315"><param name="movie" value="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US" type="application/x-shockwave-flash" width="420" height="315" allowscriptaccess="always" allowfullscreen="true"></embed></object>';
   * 'http://i1.ytimg.com/vi/Ab25nviakcw/default.jpg';
   * 'https://www.youtube.com/watch?v=BGL22PTIOAM&feature=g-all-xit';
   * 'BGL22PTIOAM';
   * @param {String} url youtube video url
   * @returns {String} youtube video ID
   */
  getYoutubeID(url) {
    var ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  }

  /**
   * Get the Vimeo ID from url
   * Supported URLS:
   * http://vimeo.com/6701902
   * http://vimeo.com/670190233
   * http://player.vimeo.com/video/67019023
   * http://player.vimeo.com/video/6701902
   * http://player.vimeo.com/video/67019022?title=0&byline=0&portrait=0
   * http://player.vimeo.com/video/6719022?title=0&byline=0&portrait=0
   * http://vimeo.com/channels/vimeogirls/6701902
   * http://vimeo.com/channels/vimeogirls/67019023
   * http://vimeo.com/channels/staffpicks/67019026
   * http://vimeo.com/15414122
   * http://vimeo.com/channels/vimeogirls/66882931
   * @param {String} url vimeo video url
   * @returns {String} vimeo video ID
   */
  getVimeoID(url) {
    var regex = new RegExp(
      /(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/
    );

    if (regex.test(url)) {
      return regex.exec(url)[5];
    }
  }

  /**
   * Check the video provider from URL
   * @param {*} url video url
   * @returns {String} Proper provider (youtube - vimeo)
   */
  checkUrlProvider(url) {
    return url.indexOf("youtu") > -1 ? "youtube" : "vimeo";
  }
}
