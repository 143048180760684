/**
 * Form Validation Class
 * element: Form
 */
export class FormValidation {
  /**
   * Main constructor
   * Set:
   *  - messages
   *  - rules
   */
  constructor(form) {
    this.form = form;
    this.submitBtn = form.querySelector(".ag-js-submit");
    this.recaptchaErrorMsg = form.querySelector(".ag-js-msj-captcha");
    this.fieldErrorsListTemplate = form.querySelector(
      "#ag-js-fieldErrorsListTemplate"
    );
    // Reglas de validación
    this.rules = {
      required: function(formControl) {
        const val = formControl.value;
        return val.length > 0;
      },
      requiredCheckbox: function(formControl) {
        return formControl.checked;
      }
    };
    // Mensajes de validación
    this.messages = {
      default: "Esta campo tiene errores de validación",
      requiredCheckbox:
        "Para continuar, haga clic en la casilla de verificación."
    };

    this.init();
  }

  /**
   * Init function
   * Handle submitBtn
   */
  init() {
    this.submitBtn.addEventListener(
      "click",
      this.handleSubmitBtnClick.bind(this)
    );
  }

  /**
   * Controlador del evento click del botón de submit.
   * @param {Event} event evento submit del formulario
   */
  handleSubmitBtnClick(event) {
    event.preventDefault();
    this.validateRecaptcha();
  }

  /**
   * Valida los campos dentro de un formulario.
   * Retorna true si todos los inputs son validos de lo contrario retorna false
   * @return {Boolean}
   */
  validateForm() {
    this.clearFormMsg();
    const validatedFormsControlSummary = Array.from(
      this.form.elements
    ).map(formControl => this.validateFormControl(formControl));

    if (
      !validatedFormsControlSummary.some(
        formControlIsValid => formControlIsValid === false
      )
    ) {
      this.form.submit();
    }
  }

  /**
   * valida que se haya realizado la validacion del recaptcha.
   * Retorna true si selccionaron la opcion del recaptcha de lo contrario retorna false
   * @return {Boolean}
   */
  validateRecaptcha() {
    if (!document.getElementById("recaptcha-key")) {
      this.validateForm();
    }
    const recaptcha_key = JSON.parse(
      document.getElementById("recaptcha-key").textContent
    );

    let form = this;
    let recaptchaErrorMsg = this.recaptchaErrorMsg;
    grecaptcha.ready(function() {
      grecaptcha
        .execute(recaptcha_key, {
          action: "submit"
        })
        .then(function(token) {
          fetch("/api/v1/recaptcha_verify/" + token)
            .then(res => res.json())
            .then(data => {
              if (data.success) {
                try {
                  recaptchaErrorMsg.classList.remove("ag-is-visible");
                } catch (e) {
                  form.validateForm();
                }
                form.validateForm();
              } else {
                recaptchaErrorMsg.classList.add("ag-is-visible");
              }
            });
        });
    });
  }

  /**
   * Retorna los mensajes de error personalizados.
   * @param {HTMLElement} formControl form control a validar
   * @param {String} validation key de validación personalizada
   * @return {Boolean}
   */
  getValidationErrorMsg(formControl, validation) {
    if (validation === "requiredCheckbox") {
      return this.messages[validation].replace(formControl.labels[0].innerHTML);
    }
    return this.messages[validation];
  }

  /**
   * Validaciones del navegador y validaciones personalizadas un form control.
   * Captura mensajes de error de las validaciones por defecto del navegador
   * Para validaciones personalizadas Debe tener name y data-validate que exista en rules
   * Renderiza Mensajes de error
   * Retorna true si el input es valido de lo contrario retorna false
   * @param {HTMLElement} formControl form control a validar
   * @return {Boolean}
   */
  validateFormControl(formControl) {
    let errorMsg = [];
    const fieldWrapperWitValidationRules = formControl.closest(
      ".ag-js-validated-formControl[data-validate]"
    );
    if (fieldWrapperWitValidationRules) {
      let validations = fieldWrapperWitValidationRules
        .getAttribute("data-validate")
        .replace(/\s/g, "")
        .split("|");
      validations.forEach(validation => {
        if (validation in this.rules && !this.rules[validation](formControl)) {
          if (validation in this.messages) {
            errorMsg.push(this.getValidationErrorMsg(formControl, validation));
          } else {
            errorMsg.push(this.messages["default"]);
          }
        }
      });
    } else if (!formControl.checkValidity()) {
      errorMsg.push(formControl.validationMessage);
    }

    if (errorMsg.length) {
      const errorsList = document.createElement("ul");
      errorMsg.forEach(msg => {
        let li = document.createElement("li");
        li.textContent = msg;
        errorsList.appendChild(li);
      });

      const messageElement = document.querySelector(
        `.ag-is-error__message[data-for='${formControl.name}']`
      );

      if (messageElement) {
        messageElement.innerHTML = errorsList.outerHTML;
        messageElement.classList.remove("ag-u-hidden");
      }
      return false;
    }

    return true;
  }

  /**
   * Limpia los mensajes de error del formulario
   */
  clearFormMsg() {
    Array.from(
      this.form.getElementsByClassName("ag-is-error__message")
    ).forEach(msg => {
      if (msg.classList.contains("ag-js-msj-captcha")) {
        msg.classList.remove("ag-is-visible");
      } else {
        msg.innerHTML = "";
        msg.classList.add("ag-u-hidden");
      }
    });
  }

  /**
   * TODO
   * Lógica contador de caracteres input
   * En el html la estructura debe estar asi:
   * Ejemplo:
      <div class="ag-form__field ag-js-form__field">
          <label class="ag-form__label">Bio</label>
          <div class="ag-form__input ag-js-formTextarea">
              <textarea name="biography" cols="40" rows="10" maxlength="800" required="" id="id_biography"></textarea>
              <span class="ag-form__helper ag-js-inputCharsCounter" data-for="biography">800/800</span>
          </div>
      </div>

   * Clases y atributos importantes para el correcto funcionamiento:
      - .ag-js-form__field : Clase del contenedor del input con su label y el contador.

      - .ag-js-inputCharsCounter : Clase para identificar los elementos que son contadores y a los cuales se les aplicara la lógica.
      - [data-for] : Atributo que vincula el elemento contador (el que tiene la clase .ag-js-inputCharsCounter) con el input, en este atributo debe ir en 'name' del input.

      - [maxlength] : Atributo que indica cuanto es el máximo de caracteres que acepta el input, debe ir en el input.
      - [name] : nombre del input, el cual se usa para vincularlo con el contador, en 'name' se debe colocar en la propiedad [data-for] del contador.
  */
  inputCharsCounterHandler() {
    Array.from(
      this.form.getElementsByClassName("ag-js-inputCharsCounter")
    ).forEach(counter => {
      // Obtenemos los elementos y datos necesarios.
      const textAreaName = counter.getAttribute("data-for");
      const container = counter.closest(".ag-js-form__field");
      const input = container.querySelector(`[name='${textAreaName}']`);
      const maxLength = input.getAttribute("maxlength");

      // Al input asociado al contador le agregamos el evento 'keyup' para detectar cuando se este escribiendo en el input y asi ir actualizando el contador.
      input.addEventListener("input", function() {
        // Saltos de línea se cuentan como dos caracteres.
        const value = input.value.replace(/(\r\n|\n|\r)/g, "  ");
        const valueLength = value.length;
        // Colocamos el texto en el contador, ejemplo: '123/800'
        counter.innerText = `${valueLength}/${maxLength}`;
        // Si la cantidad de caracteres es igual a la cantidad maxima de caracteres en el input [maxlength] se agrega la clase de error '.ag-is-error', si no la elimina si la tiene.
        if (valueLength > maxLength) {
          container.classList.add("ag-is-error");
        } else if (container.classList.contains("ag-is-error")) {
          container.classList.remove("ag-is-error");
        }
      });
      input.dispatchEvent(new Event("input"));
    });
  }
}
