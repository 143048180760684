const btnsDropdown = Array.from(
  document.getElementsByClassName("ag-js-dropdownBtn")
);

const contentDropdown = Array.from(
  document.getElementsByClassName("ag-js-dropdownContent")
);

btnsDropdown.map(btn => {
  return toggleDropdown(btn);
});

contentDropdown.map(content => {
  window.addEventListener("resize", () => {
    removeClassHide(content);
  });
  window.addEventListener("load", () => {
    removeClassHide(content);
  });
});

function toggleDropdown(btn) {
  const parentElement = btn.parentElement;
  const siblingsElement = Array.from(parentElement.children);

  btn.addEventListener("click", () => {
    typeButton(btn);
    siblingsElement.map(function(el) {
      if (el.classList.contains("ag-js-dropdownContent")) {
        el.classList.toggle("ag-is-dropdownShow");
        if (el.classList.contains("ag-c-search__dropdown")) {
          el.classList.toggle("ag-is-dropdownShowSearch");
        }
        if (
          el.classList.contains("ag-c-person-list-card") ||
          el.closest(".ag-c-items-list_image")
        ) {
          scrollTo(el);
        }
      }
    });
  });
}

function typeButton(btn) {
  if (btn.classList.contains("ag-js-dropdownBtnArrowFoot")) {
    btn.classList.toggle("ag-is-arrowUpFooter");
  } else if (btn.classList.contains("ag-js-dropdownBtnChangeText")) {
    btn.classList.toggle("ag-is-changeText");
    btn.firstElementChild.classList.toggle("ag-u-hidden");
  } else {
    btn.classList.toggle("ag-is-arrowUp");
  }
}

function scrollTo(el) {
  const siblingElement = el.closest(".ag-l-section");

  if (el.classList.contains("ag-is-dropdownShow")) {
    el.scrollIntoView({
      behavior: "smooth"
    });
  } else {
    siblingElement.scrollIntoView({
      behavior: "smooth"
    });
  }
}

function removeClassHide(content) {
  if (
    window.innerWidth >= 768 &&
    content.classList.contains("ag-c-collapsible-menu_list")
  ) {
    content.classList.remove("ag-is-dropdownHide");
  } else {
    content.classList.add("ag-is-dropdownHide");
  }
}
