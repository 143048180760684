import { isMobile } from "../utils/Utils";

// CONSTANTS
const disabled = "ag-filter-is-disabled";
const hiddenClass = "ag-filter-is-hidden";

/**
 * Malla Class
 */
export class Malla {
  /**
   * Main constructor
   * Set:
   *  - Dropdowns mobile
   *  - Filters
   *  - Modalidades
   *  - Function binding
   */
  constructor() {
    this.filters = document.querySelectorAll("[data-filter]");
    this.dropdowns = document.querySelectorAll(".ag-js-dropdown-mobile-action");
    this.modalItems = document.querySelectorAll(".js-modal-item");
    this.modalinSwiper = document.querySelectorAll(".js-modal-in-swiper");
    this.modalClose = document.querySelectorAll(".js-modal-close");
    this.modalidades = [];
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handlerDropdownClick = this.handlerDropdownClick.bind(this);
    this.init();
  }

  /**
   * Init function
   * Init Event Handlers
   * Save the arrays of modalidades
   */
  init() {
    this.handleEvents();
    if (isMobile()) {
      this.initDropdowns();
    }
  }

  /**
   * Init Event Handlers
   * Adds clicks listeners
   */
  handleEvents() {
    this.filters.forEach(element => {
      this.saveModalidades(element);
      element.addEventListener("click", e => {
        this.handleFilterClick(e, element);
      });
    });
    this.modalItems.forEach(item => {
      item.addEventListener("click", e => {
        this.modalinSwiper.forEach(modal => {
          modal.classList.add("is-modal-active");
        });
      });
    });
    this.modalClose.forEach(btn => {
      btn.addEventListener("click", e => {
        this.modalinSwiper.forEach(modal => {
          modal.classList.remove("is-modal-active");
        });
      });
    });
  }

  /**
   * Init dropdowns for mobile
   */
  initDropdowns() {
    this.dropdowns.forEach(element => {
      element.addEventListener("click", e => {
        this.handlerDropdownClick(e, element);
      });
    });
  }

  /**
   * Hanldle for dropdown cliks
   * @param {Object} e event
   * @param {Node} element main element
   */
  handlerDropdownClick(e, element) {
    const panel = element.nextElementSibling;
    if (panel) {
      element.classList.toggle("ag-is-opened");
      panel.classList.toggle("ag-is-opened");
    }
  }

  /**
   * Save arrays of modalidades
   * @param {Node} element Main dom element to get the data attributes
   */
  saveModalidades(element) {
    const modalidad = element.getAttribute("data-filter-modalidad");
    const filterId = element.getAttribute("data-filter");
    const newArray = this.modalidades[modalidad] || [];
    this.modalidades = {
      ...this.modalidades,
      [modalidad]: [...newArray, filterId]
    };
  }

  /**
   * Remove a specific filter from the array
   * @param {String} modalidad modalidad id
   * @param {String} filterId filter id
   */
  removeModalidad(modalidad, filterId) {
    const filteredItems = this.modalidades[modalidad].filter(item => {
      return item !== filterId;
    });
    this.modalidades[modalidad] = filteredItems;
  }

  /**
   * Add a specific filter to the array
   * @param {String} modalidad modalidad id
   * @param {String} filterId filter id
   */
  addModalidad(modalidad, filterId) {
    this.modalidades[modalidad] = [...this.modalidades[modalidad], filterId];
  }

  /**
   * Check the elements visibles to hide or show de message
   * @param {String} modalidad modalidad id
   */
  checkMessageVisibility(modalidad) {
    const { length } = this.modalidades[modalidad];
    const message = document.querySelectorAll(
      "[data-filter-message-id=" + modalidad + "]"
    );
    if (length <= 0) {
      message[0].style.display = "flex";
    } else {
      message[0].style.display = "none";
    }
  }

  /**
   * Hide or show elements when user clicks on a filte
   * @param {Object} e Event
   * @param {Object} element This element was clicked
   */
  handleFilterClick(e, element) {
    e.preventDefault();
    element.classList.toggle(disabled);
    const filterId = element.getAttribute("data-filter");
    const modalidad = element.getAttribute("data-filter-modalidad");
    const items = document.querySelectorAll(
      "[data-filter-group=" + filterId + "]"
    );
    this.scrollToFilter(element);
    items.forEach(item => {
      item.classList.toggle(hiddenClass);
    });
    if (element.classList.contains(disabled)) {
      this.removeModalidad(modalidad, filterId);
    } else {
      this.addModalidad(modalidad, filterId);
    }
    this.checkMessageVisibility(modalidad);
  }

  /**
   * Scroll to filter
   */
  scrollToFilter(element) {
    if (element) {
      const offset = element.closest(".js-malla-group").offsetTop - 120;

      window.scrollTo({
        top: offset,
        left: 0,
        behavior: "smooth"
      });
    }
  }
}
