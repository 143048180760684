export class videoPlay {
  constructor({ element }) {
    this.element = element;
    this.iframeParent = document.querySelector(".js-video-player");
    this.iframe = this.iframeParent
      ? this.iframeParent.querySelector("iframe")
      : null;
    this.classHideCover = "is-hidden-overlay";
    if (this.iframe) {
      this.tempSrcVideo = this.iframe.getAttribute("src");
      this.init();
    }
  }

  init() {
    this.clickEventToPlayVideoOverlay();
    this.clickEventToPlayVideoBtn();
  }

  clickEventToPlayVideoOverlay() {
    this.element.addEventListener("click", () => {
      this.hideCover();
      this.addAutoplayToSrc();
    });
  }

  /**
   * Function that active the click in the button
   */
  clickEventToPlayVideoBtn() {
    if (this.element) {
      this.element.addEventListener("click", () => {
        this.hideCover();
        this.addAutoplayToSrc();
      });
    }
  }
  /**
   * Add the autoplay param to the iframe
   */
  addAutoplayToSrc() {
    const symbol = this.iframe.src.indexOf("?") > -1 ? "&" : "?";
    this.iframe.setAttribute("src", this.iframe.src + symbol + "autoplay=1");
  }

  /**
   * Remove overlay image of the video
   */
  hideCover() {
    this.element.classList.add(this.classHideCover);
  }
}
