export class dismissibleElementHandler {
  constructor({ element }) {
    this.element = element;
    this.classToRemove = this.element.dataset.classIs;
    this.classElement = document.querySelector(".js-disable-element");
    this.parentElement = element.parentElement;
    this.iframeParent = document.querySelector(".js-video-player");
    this.iframe = this.iframeParent
      ? this.iframeParent.querySelector("iframe")
      : null;
    this.MODAL_SHOWN_KEY = "modalShown";

    this.INACTIVITY_TIME = document.querySelector("[name='duration']").value;
    this.inactivityTimer;

    this.progressCircle = document.querySelector(".js-progress-modal");
    this.progressCircleLine = this.progressCircle.querySelector(
      ".ag-c-popup__close-progress__line"
    );
    this.progressValue = 0; // Inicialmente, el progreso es 0%
    this.progressTimer = null;
    this.steps = null;

    this.init();
  }

  init() {
    this.showModalOnce();
    this.elementClic();
    this.clicOutside();
    if (!this.iframe && this.INACTIVITY_TIME != 0) {
      setTimeout(() => {
        this.closeModalAutomatically();
        this.initCounter();
      }, 5000);
    } else {
      this.progressCircle.classList.add("ag-u-hidden");
    }
  }

  /**
   * Remove class on clic
   */
  elementClic() {
    this.element.addEventListener("click", () => {
      this.removeClass();
    });
  }

  removeClass() {
    this.pauseVideo();
    this.classElement.classList.remove(this.classToRemove);
    document.querySelector(".js-navsticky").removeAttribute("style");
  }

  getMiliSeconds() {
    return Number(this.INACTIVITY_TIME) * 1000;
  }

  closeModalAutomatically() {
    // Limpiar el temporizador anterior
    clearTimeout(this.inactivityTimer);
    // Establecer un nuevo temporizador
    this.inactivityTimer = setTimeout(() => {
      this.removeClass();
    }, this.getMiliSeconds());
  }

  initCounter() {
    this.steps = Math.ceil(this.getMiliSeconds() / 1000);
    this.progressTimer = setInterval(() => this.updateProgress(), 1000);
  }

  updateProgress() {
    // Resetear el contador
    if (this.progressValue >= 100) {
      clearInterval(this.progressTimer);
      return;
    }
    this.progressValue += 100 / this.steps;
    // Establecer estilos para el progreso
    this.progressCircleLine.style.background = `conic-gradient(
      #FFF ${this.progressValue}%,
      transparent ${this.progressValue}%
    )`;
  }

  showModalOnce() {
    this.classElement.classList.add(this.classToRemove);
    document.querySelector(".js-navsticky").style.zIndex = "1";
  }

  clicOutside() {
    window.onclick = e => {
      if (!this.parentElement.contains(e.target)) {
        this.removeClass();
      }
    };
  }

  pauseVideo() {
    if (this.iframe) {
      this.iframe.removeAttribute("src");
    }
  }
}
