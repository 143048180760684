/**
 * Navbar Class
 * element: Main javascript element (js-navbar)
 */
export class Navbar {
  /**
   * Main constructor
   * Set:
   *  - Items
   *  - isSticky, currentScroll, hideMenu
   *  - Function binds
   */
  constructor({ element }) {
    this.element = element;
    this.handleScroll = this.handleScroll.bind(this);
    this.isSticky = false;
    this.currentScroll = 0;
    this.init();
  }

  /**
   * Init function
   * Handler clicks
   * Scroll listener
   */
  init() {
    window.addEventListener("scroll", this.handleScroll);
  }

  /**
   * Handler scroll event
   */
  handleScroll() {
    const scrollPos = window.scrollY;
    const elementPos = this.element.offsetTop - 54;
    const elementHeight = this.element.clientHeight;
    if (scrollPos > elementPos && !this.isSticky) {
      this.element.style.height = elementHeight + "px";
      this.isSticky = true;
      this.element.classList.add("ag-is-sticky");
    }

    if (scrollPos < elementPos && this.isSticky) {
      this.element.style.height = "auto";
      this.isSticky = false;
      this.element.classList.remove("ag-is-sticky");
    }

    this.currentScroll = scrollPos < 0 ? 0 : scrollPos;
  }
}
