export class PanelScroll {
  constructor(element) {
    this.element = element;
    this.init();
  }

  /**
   * Init function
   * Click listener
   */
  init() {
    const parentElement = this.element.closest(".js-panelTitle");
    const siblingNextParent = parentElement.nextElementSibling;
    const contentPanel = document.querySelectorAll(".js-panelContent");

    this.element.addEventListener("click", () => {
      for (let i = 0; i < contentPanel.length; i++) {
        const listPanels = contentPanel[i];
        if (listPanels.classList.contains("ag-is-content-show")) {
          listPanels.classList.remove("ag-is-content-show");
          listPanels.parentElement.classList.remove("ag-is-panel-show");
        }
      }
      siblingNextParent.classList.add("ag-is-content-show");
      parentElement.parentElement.classList.add("ag-is-panel-show");
    });
  }
}
