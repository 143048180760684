export class Sharebox {
  constructor({ element }) {
    this.element = element;
    this.button = this.element.querySelectorAll(".js-sharebox-button");
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.init();
  }

  init() {
    this.button[0].addEventListener("click", this.handleButtonClick);
  }

  handleButtonClick() {
    this.element.classList.toggle("is-opened");
  }
}
