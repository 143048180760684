import * as UTILS from "../utils/Utils";
export class Header {
  constructor() {
    this.header = document.querySelectorAll(".js-header");
    this.langButton = document.querySelectorAll(".js-language-button");
    this.hamburguerMenuButton = document.querySelectorAll(".js-button-menu");
    this.itemsHasSubmenu = document.querySelectorAll(".js-item-has-submenu");
    this.secondaryItemsHasSubmenu = document.querySelectorAll(
      ".js-secondary-item-has-submenu"
    );
    this.submenuTitle = document.querySelectorAll(".js-submenu-category");
    this.langBox = document.querySelectorAll(".js-language-box");
    this.hamburguerMenu = document.querySelectorAll(".js-main-nav-menu");

    this.hanldeLanguageButtonClick = this.hanldeLanguageButtonClick.bind(this);
    this.hanldeItemHasSubmenuClick = this.hanldeItemHasSubmenuClick.bind(this);
    this.hanldeSubmenuTitleClick = this.hanldeSubmenuTitleClick.bind(this);
    this.hanldeHamburguerButtonClick = this.hanldeHamburguerButtonClick.bind(
      this
    );
    this.init();
  }

  init() {
    this.initHandlers();
  }

  initHandlers() {
    this.langButton[0].addEventListener(
      "click",
      this.hanldeLanguageButtonClick
    );
    this.hamburguerMenuButton[0].addEventListener(
      "click",
      this.hanldeHamburguerButtonClick
    );
    this.itemsHasSubmenu.forEach(element => {
      element.addEventListener("click", event => {
        this.hanldeItemHasSubmenuClick(element, event);
      });
    });
    this.submenuTitle.forEach(element => {
      element.addEventListener("click", this.hanldeSubmenuTitleClick);
    });
    this.secondaryItemsHasSubmenu.forEach(element => {
      element.addEventListener("click", event => {
        this.hanldeSecondaryItemClick(event, element);
      });
    });
  }

  hanldeLanguageButtonClick() {
    this.langBox[0].classList.toggle("ag-is-opened");
    return false;
  }

  hanldeHamburguerButtonClick() {
    this.hamburguerMenu[0].classList.toggle("ag-is-opened");
    this.header[0].classList.toggle("ag-is-active");
    return false;
  }

  hanldeItemHasSubmenuClick(element, event) {
    if (event.target === element || event.target.nodeName === "SPAN") {
      element.classList.toggle("ag-is-opened-megamenu");
    }
    return false;
  }

  hanldeSubmenuTitleClick(event) {
    event.target.parentElement.classList.toggle("ag-is-opened-submenu");
    return false;
  }

  hanldeSecondaryItemClick(event, element) {
    if (event.target === element || event.target.nodeName === "SPAN") {
      element.classList.toggle("is-active");
      element.classList.toggle("is-menu-opened");
      return false;
    }
  }
}
