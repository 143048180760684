const btnSearch = Array.from(document.querySelectorAll(".js-button-search"));
const dialogSearch = Array.from(
  document.querySelectorAll("[data-dialog='dialogSearch']")
);

const btnClose = Array.from(
  document.getElementsByClassName("js-search-dialogClose")
);

btnSearch.map(btn => {
  return openDialog(btn);
});

btnClose.map(btn => {
  return closeDialog(btn);
});

function openDialog(btn) {
  btn.addEventListener("click", () => {
    dialogSearch.map(function(el) {
      el.classList.add("ag-is-search-dialogActive");
    });
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  });
}

function closeDialog(btn) {
  btn.addEventListener("click", () => {
    dialogSearch.map(function(el) {
      el.classList.remove("ag-is-search-dialogActive");
    });
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 500);
  });
}
